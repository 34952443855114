import { template as template_7aba7f31baf5491bac7f533c189898cc } from "@ember/template-compiler";
const FKText = template_7aba7f31baf5491bac7f533c189898cc(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
