import { template as template_90bba0bb2a2c4bceb80710ca792660b4 } from "@ember/template-compiler";
const WelcomeHeader = template_90bba0bb2a2c4bceb80710ca792660b4(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
