import { template as template_2059b095e07f43b9ab04f43d35064b5e } from "@ember/template-compiler";
const FKLabel = template_2059b095e07f43b9ab04f43d35064b5e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
